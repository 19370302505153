import React, { useRef, useEffect, useState } from 'react';

import * as Util from "../utils/util";
import { BASE_URL, handleBlur, handleFocus } from "../utils/util";
import InputField from "./components/InputField";
import { countries } from "../utils/countries";
import axios from "axios";
import MainApp from "./components/MainApp";
import AppLoading from "./components/AppLoading";

const PhoneNumberRedirect = () => {
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [dialCode, setDialCode] = useState("+234");
	const [number, setNumber] = useState("");
	const [error, setError] = useState("");
	const inputRef = useRef();

	useEffect(() => {
		axios.get('https://ipapi.co/json').then(response => {
			const locationData = response.data;
			if(!locationData || !locationData['country_calling_code']) return;
			setDialCode(locationData['country_calling_code']);
		}).catch(err => {
			console.error(err);
			setDialCode(countries[0]['dialCode']);
		}).finally(async () => {
			try {
				const phoneNumber = localStorage.getItem("sess:phone_number");
				if(phoneNumber) await initPhoneRedirect(phoneNumber);
			} catch (e) { console.error(e); }
			setPageLoading(false);
		});
	}, []);

	const initPhoneRedirect = async (phoneNumber) => {
		const response = await axios.get(`${BASE_URL}/api/msisdn/${phoneNumber}`);
		if(response.data.refid) {
			let reference = (response.data.meta || {})['paymentRef'] || response.data.refid;
			localStorage.setItem("sess:phone_number", phoneNumber);
			const currentPage = window.location.href.toLowerCase();
			const env = currentPage.includes("staging") ? ".staging" : currentPage.includes("sandbox") ? ".sandbox" : "";
			// window.location.replace(`https://widgets.vendy.money/payment/${reference}`);
			window.location.replace(`https://checkout${env}.myvendy.com/${reference.split(":")[0]}`);
		} else {
			setError("Unable to verify you phone number at this time, please try again later.");
			setLoading(false);
		}
	};

	const validatePhoneNumber = async (event) => {
		event.preventDefault();
		if(buttonDisabled || loading) return;
		if(number.trim().length < 7) {
			setError("Enter a valid phone number");
			return;
		}

		setError("");
		setButtonDisabled(true);
		let phoneNumber = number.trim().replaceAll("+", "");
		const preferredDialCode = dialCode.replaceAll("+", "");
		if(!phoneNumber.startsWith(preferredDialCode)) phoneNumber = preferredDialCode+phoneNumber;
		if(phoneNumber.startsWith("2340")) phoneNumber = phoneNumber.replace("2340", "234");
		if(phoneNumber.startsWith("2540")) phoneNumber = phoneNumber.replace("2540", "254");

		try {
			setError("");
			setButtonDisabled(false);
			setLoading(true);
			await initPhoneRedirect(phoneNumber);
		} catch (err) {
			console.error(err);
			setError(err.message);
		} finally {
			setLoading(false);
			setButtonDisabled(false);
		}
	};

	const optionChangeHandler = (event) => {
		setDialCode(event.target.value.trim());
	};

	const numberChangeHandler = (event) => {
		if(isNaN(event.target.value.trim())) return;
		setButtonDisabled(event.target.value.trim().length < 7);
		setNumber(event.target.value.trim());
		setError('');
	};

	if(pageLoading) return <AppLoading message={"Loading..."} />;

	const style = {};
	if(Util.device === 'ios') {
		style.top = "64px";
	}

	let versionNumber = navigator.userAgent.split(" ");
	versionNumber = versionNumber[versionNumber.length - 1].split("/");
	versionNumber = versionNumber[versionNumber.length - 1];

	return (
		<MainApp>
			<div className="title" style={{ marginTop: "100px" }}>{ "Welcome to Vendy"}</div>
			<div className="subtitle">Verify your whatsapp number to continue</div>
			<form onSubmit={ validatePhoneNumber } style={{ marginTop: "40px" }}>
				<div className="form-field-group">
					<div className="upi-widget-text-field">
						<label htmlFor="dial-code" className={"upi-widget-text-field-focused-label"}>Country/Region</label>
						<select id="dial-code" onChange={optionChangeHandler} defaultValue={dialCode}>{ countries.map(function(country){
							return <option key={country['dialCode']+country['name']} value={country['dialCode']}>{country['name']} ({country['dialCode']})</option>;
						}) }</select>
					</div>
					<InputField
						id="number"
						type="text"
						name="number"
						value={number}
						autoFocus={true}
						inputMode="numeric"
						inputRef={inputRef}
						onChange={numberChangeHandler}
						onFocus={handleFocus}
						onBlur={handleBlur}
						label="Phone number"
					/>
				</div>
				{error && <div className="upi-widget-text-error">
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				<button className={`rounded-button bottom${buttonDisabled || loading ? " disabled" : ""}`} type="submit">Continue</button>
				<div style={{position: "absolute", textAlign: "center", marginTop: "8px", fontSize: "12px", bottom: "16px", left: "0", right: "0" }}>Version {versionNumber}</div>
			</form>
		</MainApp>
	);
};

export default PhoneNumberRedirect;
