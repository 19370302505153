import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import * as Util from "../../utils/util";
import { BASE_URL, handleFocus, handleBlur } from "../../utils/util";
import IOSButton from "./IOSButton";
import Loading from "./Loading";
import axios from "axios";
import InputField from "./InputField";
import {encrypt} from "../../utils/crypto";

const Address = ({ transaction, onValidated, requestPath }) => {
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [error, setError] = useState('');
	const [address, setAddress] = useState('');
	const [addressError, setAddressError] = useState('');
	const [city, setCity] = useState('');
	const [cityError, setCityError] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [zipCodeError, setZipCodeError] = useState('');
	const [stateCode, setStateCode] = useState('');
	const [stateError, setStateError] = useState('');

	const supportedProvinces = ["CA", "GB", "UK"];
	const [useProvinces, setUseProvinces] = useState(false);
	const [avStateList, setAvStateList] = useState([{"name": "Select State", "slug": "", "abbreviation": ""}]);

	const navigate = useNavigate();
	const inputRef = useRef();

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		if(inputRef.current) inputRef.current['focus']();

		if(transaction.paymentCardMeta && transaction.paymentCardMeta['countryCode']) {
			const countryCode = transaction.paymentCardMeta['countryCode'].trim().toUpperCase();
			const emptyState = [{"name": "Select Province", "slug": "", "abbreviation": ""}];
			if(supportedProvinces.indexOf(countryCode) > -1) {
				setAvStateList(emptyState);
				setUseProvinces(true);
			}

			const fetchTrxAVStates = async () => {
				try {
					const absUrl = `${BASE_URL}/api/payment/:session/avs-states/${countryCode}`;
					const resp = await axios.get(absUrl);
					const response = resp.data;

					setAvStateList([].concat(emptyState, response.data));
				} catch (err) {
					if(error.response && error.response.status === 404) {
						setError("Payment card not supported, select another payment method");
					} else {
						setError(err.message);
						console.error('Status fetching av_states:', error);
					}
				}
			};

			fetchTrxAVStates().then(_ => { /* ... */ });
		} else {
			setError("Payment card not supported, select another payment method");
		}
	}, []);

	useEffect(() => {
		isValidData();
	}, [address, zipCode, city, stateCode]);

	const handleAddressChange = (event) => {
		setAddress(event.target.value);
	};

	const handleZipCodeChange = (event) => {
		setZipCode(event.target.value);
	};

	const handleCityChange = (event) => {
		setCity(event.target.value);
	};

	const handleStateChange = (event) => {
		let newValue = event.target.value;
		setStateCode(newValue);
	};

	const isValidData = (showError=false) => {
		setAddressError('');
		setZipCodeError('');
		setStateError('');
		setCityError('');
		setError('');

		console.log({ stateCode });
		if(address.trim().length < 5) {
			if(showError) setAddressError('Enter a valid address');
			setButtonDisabled(true);
			return false;
		} else if(zipCode.trim().length < 5){
			if(showError) setZipCodeError('Enter a valid zip code');
			setButtonDisabled(true);
			return false;
		} else if(city.trim().length < 3){
			if(showError) setZipCodeError('Enter a valid city');
			setButtonDisabled(true);
			return false;
		} else if(stateCode.trim().length < 2) {
			if(showError) setStateError(`Select a valid ${useProvinces ? 'province/region' : 'state'}`);
			setButtonDisabled(true);
			return false;
		}

		setButtonDisabled(false);
		return true;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(loading || !isValidData(true)) return;

		try {
			const data = {
				"address": address.trim(),
				"city": city.trim(),
				"state": stateCode.trim(),
				"zip_code": zipCode.trim()
			};

			setLoading(true);
			const payload = encrypt(JSON.stringify(data));
			const response = await axios.post(`${BASE_URL}/api/payment/${transactionRef}${requestPath || '/auth'}`, { payload });
			if (response.data.status === 'completed' || response.data.status === 'successful'
				|| response.data.status === 'failed' || response.data.status === 'reversed'
				|| response.data.status === 'vended' || response.data.status === 'debited') {
				navigate(`/payment/${transactionRef}/status`, { replace: true });
			} else if(onValidated) {
				setAddress("");
				onValidated(response.data);
			}
		} catch (error) {
			if(!error.response) {
				console.error('Error processing transaction:', error);
				setError(error.toString());
				return;
			}

			const response = error.response;
			let errorMsg = response.statusText;
			if(response.data && response.data.message) {
				errorMsg = response.data.message;
			} else if(response.data && response.data.error) {
				errorMsg = response.data.error.message || response.data.error.toString();
			} setError(errorMsg);

			if(response.status === 408 || response.status === 504) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 100);
			} else if(response.status === 403 || response.status === 410) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 350);
			}
		} finally {
			setLoading(false);
		}
	};

	const subtitle = (transaction.nextStep || {}).message;
	return (
		<div className="upi-widget-container" style={loading && Util.device !== 'ios' ? { maxHeight: "250px" } : {}}>
			<form onSubmit={handleSubmit}>
				<div className="upi-widget-title">Enter address</div>
				<div className="upi-widget-subtitle">{subtitle ? subtitle : 'Enter the payment address associated with your card'}</div>
				<InputField
					type="text"
					id="address"
					name="address"
					value={address}
					autoFocus={true}
					inputRef={inputRef}
					onChange={handleAddressChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					label={'Address'}
					error={addressError}
				/>
				<InputField
					type="text"
					id="zipcode"
					name="zipcode"
					value={zipCode}
					placeholder={`${useProvinces ? 'Postal' : 'Zip'} Code`}
					onChange={handleZipCodeChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					label="Zip Code"
					error={zipCodeError}
				/>
				<div className="upi-widget-input-wrapper">
					<InputField
						type="text"
						id="city"
						name="city"
						value={city}
						placeholder={'City'}
						onChange={handleCityChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						label="City"
						error={cityError}
						inputStyle={{ width: '100%' }}
						style={{ marginRight: '5px' }}
					/>
					<div className={`upi-widget-text-field${stateError ? ' error' : ''}`} style={{ marginLeft: '5px' }}>
						<label htmlFor="state" className={stateCode ? "upi-widget-text-field-focused-label" : ''}>
							{useProvinces ? 'Province/Region' : 'State'}
						</label>
						<select id="state" value={stateCode} onChange={handleStateChange} style={{ opacity: stateCode ? 1 : 0 }}>
							{avStateList.map((option, index) => (
								<option key={index} value={option.abbreviation}>{option.name}</option>
							))}
						</select>
					</div>
				</div>
				{error && <div className="upi-widget-text-error">
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				<div className="upi-widget-footer">
					<div className="left"><button type="button" onClick={Util.closeApp}>Cancel</button></div>
					<div className="vertical-line"/>
					<div className="right">
						{Util.device === 'ios' && (<IOSButton type="submit" className={buttonDisabled ? 'disabled' : ''} isLoading={loading}/>)}
						{Util.device !== 'ios' && (<button type="submit">Next</button>)}
					</div>
				</div>
			</form>
			{ loading && Util.device !== 'ios' && <Loading message={'Authorizing...'} /> }
		</div>
	);
};

export default Address;
