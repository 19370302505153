import React, { useState, useEffect } from 'react';
import { /*useNavigate, */useParams } from 'react-router-dom';
import { BASE_URL } from "../utils/util";
import Loading from "./components/Loading";
import axios from "axios";
import Status from "./components/Status";
import { formatCurrency } from "../utils/formatters";

const TransactionStatus = ({ transaction }) => {
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [updatedTransaction, setUpdatedTransaction] = useState(transaction);
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState('');
	const [statusMessage, setStatusMessage] = useState('');
	const [dateTime, setDateTime] = useState(Date.now());
	const timeoutLimit = 10 * 1000;
	// const navigate = useNavigate();

	const supportedInfoStatuses = [ 'vended', 'processing', 'debited', 'reversing', 'delivered', 'pending' ];

	useEffect(() => {
		setDateTime(Date.now());
		if(updatedTransaction && updatedTransaction.status !== 'pending'){
			generateStatusContent(updatedTransaction);
		}

		const updateTransaction = async () => {
			try {
				const ref = transactionRef.trim().toLowerCase();
				const query = ref === "test_random_demo" || ref === "random_demo" ? '?status=1' : '';
				const response = await axios.get(`${BASE_URL}/api/payment/${transactionRef}${query}`);
				setUpdatedTransaction(response.data);
				return response.data;
			} catch (error) {
				if(error.response && error.response.status === 404) {
					setStatus('Not found');
					setStatusMessage('Payment not found. Verify the payment reference and try again');
				} else if(error.response) {
					setStatus(error.response.statusText);
					setStatusMessage('Unable to fetch the payment details at this time');
				} else {
					setStatus('No connection');
					setStatusMessage(error.toString());
					console.error('Status fetching transaction:', error);
				}
			}
		};

		const retryTransactionUpdate = () => {
			updateTransaction().then(transaction => {
				if(!transaction && dateTime > (Date.now() - timeoutLimit)) {
					setTimeout(() => retryTransactionUpdate(), 950);
					return;
				}

				generateStatusContent(transaction);
				if(dateTime > (Date.now() - timeoutLimit) && supportedInfoStatuses.includes(transaction.status)) {
					setTimeout(() => retryTransactionUpdate(), 950);
				}
			}).finally(_ => {
				setLoading(false);
			});
		};

		retryTransactionUpdate();
	}, []);

	const generateStatusContent = (transaction) => {
		if(!transaction) return;
		if(transaction.status === 'completed' || transaction.status === 'successful') {
			setStatus('Payment completed');
			setStatusMessage(`Your payment of ${transaction.currency.toUpperCase()}${formatCurrency(transaction.amount)} was completed successfully`);
		} else if(transaction.status === 'failed' || transaction.status === 'reversed') {
			setStatus(`Payment ${transaction.status}`);
			setStatusMessage(transaction.reason || transaction.rawReason || "An error occurred while processing your request!");
		} if(dateTime > (Date.now() - timeoutLimit) && supportedInfoStatuses.includes(transaction.status)) {
			setStatus('Verifying Payment');
			setStatusMessage('Payment verification ongoing, we would let you know once the status changes');
		} else if(transaction.status === 'vended' || transaction.status === 'processing' || transaction.status === 'debited') {
			setStatus('Payment processing');
			setStatusMessage('Your payment request is being processed, we would let you know once the status changes');
		} else if(transaction.status === 'reversing') {
			setStatus('Reversal ongoing');
			setStatusMessage('A reversal has been initiated on this transaction, we would let you know once the status changes');
		} else if(transaction.status === 'delivered') {
			// setStatus('Payment pending');
			// setStatusMessage('Your payment request is awaiting your approval');
			setStatus('Payment processing');
			setStatusMessage('Your payment request is being processed, we would let you know once the status changes');
			// navigate(`/payment/${transactionRef}`, { replace: true });
		} else if(transaction.status === 'pending') {
			setStatus('Payment processing');
			setStatusMessage('Your payment request is being processed, we would let you know once the status changes');
			// navigate(`/payment/${transactionRef}`, { replace: true });
		}
	};

	if (loading && !updatedTransaction) {
		return <Loading message={'Checking status...'} />;
	}

	return (
		<Status
			title={status}
			message={statusMessage}
			rawStatus={updatedTransaction ? (updatedTransaction.status || 'pending') : 'pending'}
			verifying={dateTime > (Date.now() - timeoutLimit) && updatedTransaction && supportedInfoStatuses.includes(updatedTransaction.status)}
		/>
	);
};

export default TransactionStatus;
