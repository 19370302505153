import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppLoading from "./components/AppLoading";
import axios from "axios";
import { BASE_URL } from "../utils/util";
import TransactionTile from "./components/TransactionTile";
import MainApp from "./components/MainApp";

let searchQueryDeferTimeout = null;
const Transactions = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [transactions, setTransactions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const location = useLocation();
	if(!location.state || !location.state.authToken) {
		navigate('/', { replace: true });
	}

	const { authToken } = location.state;
	const headers = {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${authToken}`,
	};

	useEffect(() => {
		const visibleHeight = window.innerHeight;
		document.documentElement.style.setProperty('--transaction-list-height', `${visibleHeight - 178}px`);
		fetchTransactions(0);
	}, []);

	useEffect(() => {
		searchQueryDeferTimeout = setTimeout(() => {
			searchQuery.trim() === "" ? fetchTransactions(0) : initSearchRequest(0);
		}, 1350);
	}, [ searchQuery ]);

	const fetchTransactions = (offset, limit=100) => {
		setError(""); if(!loading) setLoading(true);
		axios.get(`${BASE_URL}/app/transactions`, { headers, withCredentials: false, params: { offset, limit } })
			.then(response => {
				setTransactions(response.data)
			})
			.catch(err => {
				console.error(err);
				setError(err.message);
			}).finally(() => setLoading(false));
	};

	const initSearchRequest = (offset, limit=100) => {
		setError(""); if(!loading) setLoading(true);
		const q = searchQuery.replace(/[^\w\s]/gi, ''); // sanitize query
		axios.get(`${BASE_URL}/app/search`, { headers, withCredentials: false, params: { q, offset, limit } })
			.then(response => setTransactions(response.data))
			.catch(err => {
				console.error(err);
				setError(err.message);
			}).finally(() => setLoading(false));
	};

	const searchQueryHandler = (event) => {
		if(searchQueryDeferTimeout) clearTimeout(searchQueryDeferTimeout);
		setSearchQuery(event.target.value);
	};

	const navigateTo = (transaction) => {
		navigate(`/transactions/${transaction.refId}`, { state: { authToken: location.state.authToken, transaction } });
	};

	if(loading && (transactions || []).length === 0) return <AppLoading message={"Loading..."} />;
	if(error && (transactions || []).length === 0) {
		return <AppLoading message={error} />; // TODO LARGE ERROR SCREEN HERE
	}

	return (
		<MainApp style={{ padding: "0" }}>
			<div className="app-bar"><h1 className="title">Transactions</h1></div>
			<div className="search-field" style={{ margin: "0 16px 16px" }}>
				<input id="search" name="search" value={searchQuery} type="text" onChange={searchQueryHandler} placeholder="Search transactions" data-private="redact"/>
			</div>
			<div className="transaction-list">
				{ transactions.map(value => <TransactionTile key={value.refId} transaction={value} onTap={(event) => {
					event.preventDefault();
					navigateTo(value);
				}} />) }
			</div>
			{error && <div className="footer-message">{error}</div>}
		</MainApp>
	);
};

export default Transactions;
