import React from "react";
import { triggerHapticEffect } from "../../utils/util";

const InputField = ({ id, name, type, value, inputRef, style, inputStyle, label, placeholder, pattern, autoFocus, inputMode, onChange, onFocus, onBlur, error, trailing }) => {

	return (
		<div className={`upi-widget-text-field${error ? ' error' : ''}`} style={style}>
			<label htmlFor={id || name} className={value ? "upi-widget-text-field-focused-label" : ''}>
				{ label }
			</label>
			<input
				ref={inputRef}
				type={type}
				value={value}
				style={inputStyle}
				id={id || name}
				name={name || id}
				data-private="redact"
				placeholder={placeholder}
				autoFocus={autoFocus}
				inputMode={inputMode}
				pattern={pattern}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onClick={triggerHapticEffect}
			/>
			{trailing}
		</div>
	);
};

export default InputField;
