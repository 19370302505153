import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Card from './components/Card';
import Pin from './components/Pin';
import { BASE_URL, loadScript } from '../utils/util';
import OTPPhoneNumber from './components/OTPPhoneNumber';
import Loading from "./components/Loading";
import Status from "./components/Status";
import * as Util from "../utils/util";
import LogRocket from "logrocket";
import Address from "./components/Address";
import Birthday from "./components/Birthday";

const NewTransaction = () => {
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [transaction, setTransaction] = useState(null);
	const [error, setError] = useState(null);
	const [step, setStep] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const hostname = (window.location.hostname || '').toLowerCase();
		if(transactionRef.toLowerCase().startsWith("sandbox") && !hostname.includes(".sandbox")) {
			window.location.replace(`https://widgets.sandbox.vendy.money${window.location.pathname}`);
		}
	}, []);

	useEffect(() => {
		const fetchTransaction = async () => {
			try {
				const response = await axios.get(`${BASE_URL}/api/payment/${transactionRef}`);
				setTimeout(_ => {
					setTransaction(response.data);
					if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') return;
					LogRocket.identify(response.data.msisdn, {
						ref: response.data.refId,
						currency: response.data.currency,
						channel: response.data.channel,
						method: response.data.method,
						status: response.data.status
					});
				}, 1350);
			} catch (err) {
				setError(err.message);
			}
		};

		fetchTransaction().then(_ => { /* ... */ });
	}, [transactionRef]);

	useEffect(() => {
		if (transaction) {
			if (transaction.status === 'completed' || transaction.status === 'successful'
				|| transaction.status === 'failed' || transaction.status === 'reversed'
				|| transaction.status === 'vended' || transaction.status === 'debited') {
				navigate(`/payment/${transactionRef}/status`, { replace: true });
			} else if(transaction.method && transaction.method.identifier === 'new_payment_card'){
				setStep('card');
			} else if(transaction.method && transaction.method.identifier === 'apple_pay') { // APPLE PAY HOT FIX
				const pathname = window.location.pathname.split(":")[0];
				window.location.href = `${window.location.protocol}//${window.location.hostname}${pathname}/apple-pay`;
			} else {
				const nextStep = ((transaction.nextStep || {}).page || '').toLowerCase().trim();
				if((nextStep === 'embed-page' || nextStep === 'url') && transaction.meta && (transaction.meta.url || transaction.nextStep.url)) {
					if(transaction.meta['xSScript']) Util.runJavaScript(transaction.meta['xSScript']);
					window.location.href = transaction.meta.url || transaction.nextStep.url;
				} else if(nextStep === 'embed-js-page' && transaction.meta && transaction.meta['script']) {
					if(transaction.meta.url) return loadScript(transaction.meta.url).then(_ => {
						if(transaction.meta['xSScript']) Util.runJavaScript(transaction.meta['xSScript']);
						new Function(transaction.meta['script'])();
						if(transaction.meta['xSScript']) Util.runJavaScriptInIFrame(transaction.meta['xSScript']);
					}).catch((error) => {
						console.error('Error loading the script:', error);
					});
					return new Function(transaction.meta['script'])();
				}

				setStep(nextStep);
			}
		}
	}, [transaction]);

	const handleValidated = (updatedTransaction) => {
		setTransaction(updatedTransaction);
		setStep(updatedTransaction.nextStep.page.toLowerCase());
	};

	const handleOTPValidated = (updatedTransaction) => {
		setTransaction(updatedTransaction);
		navigate(`/payment/${transactionRef}/status`, { replace: true });
	};

	if (!transaction) {
		return <Loading message={'Connecting...'} animate={true} />;
	}

	if(error) {
		return <Status title={'No connection'} message={'Unable to reach server, check your internet connection and try again'} />;
	}

	return (
		<div>
			{step === 'card' && (
				<Card transaction={transaction} />
			)}
			{step === 'pin' && (
				<Pin transaction={transaction} onValidated={handleValidated} />
			)}
			{step === 'cvv' && (
				<Pin transaction={transaction} isCVV={true} onValidated={handleValidated} />
			)}
			{step === 'otp' && (
				<OTPPhoneNumber transaction={transaction} onValidated={handleOTPValidated} />
			)}
			{step === 'phone' && (
				<OTPPhoneNumber transaction={transaction} onValidated={handleValidated} />
			)}
			{step === 'address' && (
				<Address transaction={transaction} onValidated={handleValidated} />
			)}
			{step === 'birthday' && (
				<Birthday transaction={transaction} onValidated={handleValidated} />
			)}
		</div>
	);
};

export default NewTransaction;
