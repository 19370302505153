import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import * as Util from "../../utils/util";
import { BASE_URL, handleFocus, handleBlur } from "../../utils/util";
import IOSButton from "./IOSButton";
import Loading from "./Loading";
import axios from "axios";
import InputField from "./InputField";
import {encrypt} from "../../utils/crypto";

let _otpRequested = false;
const OTPPhoneNumber = ({ transaction, onValidated, requestPath }) => {
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [otp, setOtp] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	// const [otpRequested, setOtpRequested] = useState(false);
	const navigate = useNavigate();
	const inputRef = useRef();

	const currentStep = ((transaction.nextStep || {}).page || 'otp').toLowerCase();

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		if(inputRef.current) inputRef.current['focus']();
		requestOTP();
	}, []);

	const requestOTP = () => {
		if(currentStep !== 'otp' || _otpRequested) return;
		// setOtpRequested(true);
		_otpRequested = true;
		axios.post(`${BASE_URL}/api/payment/${transactionRef}/otp`, {}).catch(reason => {
			console.error('Error requesting OTP:', reason);
			// setOtpRequested(false);
			_otpRequested = false;
		});
	};

	const handleChange = (event) => {
		setButtonDisabled(event.target.value.trim().length < 4);
		setOtp(event.target.value);
		setError('');
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(loading) return;
		if(otp.trim().length < 6) {
			setError(`Enter a valid ${currentStep === 'otp' ? 'OTP' : 'phone number'}`);
			return;
		}

		try {
			const data = {};
			setLoading(true);
			data[currentStep === 'otp' ? 'otp' : 'phone'] = otp.trim();
			const payload = encrypt(JSON.stringify(data));
			const response = await axios.post(`${BASE_URL}/api/payment/${transactionRef}${requestPath || '/auth'}`, { payload });
			if (response.data.status === 'completed' || response.data.status === 'successful'
				|| response.data.status === 'failed' || response.data.status === 'reversed'
				|| response.data.status === 'vended' || response.data.status === 'debited') {
				navigate(`/payment/${transactionRef}/status`, { replace: true });
			} else if(onValidated) {
				setOtp("");
				onValidated(response.data);
			}
		} catch (error) {
			if(!error.response) {
				console.error('Error processing transaction:', error);
				setError(error.toString());
				return;
			}

			const response = error.response;
			let errorMsg = response.statusText;
			if(response.data && response.data.message) {
				errorMsg = response.data.message;
			} else if(response.data && response.data.error) {
				errorMsg = response.data.error.message || response.data.error.toString();
			} setError(errorMsg);

			if(response.status === 408 || response.status === 504) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 100);
			} else if(response.status === 403 || response.status === 410) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 350);
			}
		} finally {
			setLoading(false);
		}
	};

	const subtitle = (transaction.nextStep || {}).message;
	return (
		<div className="upi-widget-container">
			<div className="upi-widget-header">
				<div className="upi-widget-brand-logo">
					<img src={`/assets/images/logos/${transaction.brand.logo || 'default.png'}`} alt="logo"/>
				</div>
				<div className="upi-widget-brand-name">
					<h1>{transaction.brand.name || 'Pay with Vendy'}</h1>
				</div>
			</div>
			<div style={{ clear: 'both' }}/>
			<hr style={{ marginTop: '12px' }}/>
			<form onSubmit={handleSubmit}>
				<div className="upi-widget-title">{`Enter ${currentStep === 'otp' ? 'OTP' : 'phone number'}`}</div>
				<div className="upi-widget-subtitle">{subtitle ? subtitle : `Enter the ${
					currentStep === 'otp' ? 'phone number connected to your bank account'
						: '6-digit One-Time-Password sent to your phone number'
				}`}</div>
				<InputField
					id="otp"
					name="otp"
					value={otp}
					type="number"
					autoFocus={true}
					inputRef={inputRef}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					label={`${currentStep === 'otp' ? 'OTP Code' : 'Phone number'}`}
					error={error}
				/>
				{error && <div className="upi-widget-text-error">
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				<div className="upi-widget-footer">
					<div className="left"><button type="button" onClick={Util.closeApp}>Cancel</button></div>
					<div className="vertical-line"/>
					<div className="right">
						{Util.device === 'ios' && (<IOSButton type="submit" className={buttonDisabled ? 'disabled' : ''} isLoading={loading}/>)}
						{Util.device !== 'ios' && (<button type="submit">Next</button>)}
					</div>
				</div>
			</form>
			{ loading && Util.device !== 'ios' && <Loading message={'Authorizing...'} /> }
		</div>
	);
};

export default OTPPhoneNumber;
