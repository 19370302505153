import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import * as Util from "../../utils/util";
import { BASE_URL, handleFocus, handleBlur } from "../../utils/util";
import IOSButton from "./IOSButton";
import Loading from "./Loading";
import axios from "axios";
import InputField from "./InputField";
import {encrypt} from "../../utils/crypto";

const Birthday = ({ transaction, onValidated, requestPath }) => {
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [birthday, setBirthday] = useState('');
	const navigate = useNavigate();
	const inputRef = useRef();
	const [hasFocus, setHasFocus] = useState(false);

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		if(inputRef.current) inputRef.current['focus']();
	}, []);

	const handleChange = (event) => {
		setButtonDisabled(event.target.value.trim().length < 4);
		setBirthday(event.target.value);
		setError('');
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(loading) return;
		if(birthday.trim().length < 10) {
			setError(`Enter a your date of birth`);
			return;
		}

		try {
			setLoading(true);
			const payload = encrypt(JSON.stringify({ 'birthday': birthday.trim() }));
			const response = await axios.post(`${BASE_URL}/api/payment/${transactionRef}${requestPath || '/auth'}`, { payload });
			if (response.data.status === 'completed' || response.data.status === 'successful'
				|| response.data.status === 'failed' || response.data.status === 'reversed'
				|| response.data.status === 'vended' || response.data.status === 'debited') {
				navigate(`/payment/${transactionRef}/status`, { replace: true });
			} else if(onValidated) {
				setBirthday("");
				onValidated(response.data);
			}
		} catch (error) {
			if(!error.response) {
				console.error('Error processing transaction:', error);
				setError(error.toString());
				return;
			}

			const response = error.response;
			let errorMsg = response.statusText;
			if(response.data && response.data.message) {
				errorMsg = response.data.message;
			} else if(response.data && response.data.error) {
				errorMsg = response.data.error.message || response.data.error.toString();
			} setError(errorMsg);

			if(response.status === 408 || response.status === 504) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 100);
			} else if(response.status === 403 || response.status === 410) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 350);
			}
		} finally {
			setLoading(false);
		}
	};

	const subtitle = (transaction.nextStep || {}).message;
	return (
		<div className="upi-widget-container">
			<div className="upi-widget-header">
				<div className="upi-widget-brand-logo">
					<img src={`/assets/images/logos/${transaction.brand.logo || 'default.png'}`} alt="logo"/>
				</div>
				<div className="upi-widget-brand-name">
					<h1>{transaction.brand.name || 'Pay with Vendy'}</h1>
				</div>
			</div>
			<div style={{ clear: 'both' }}/>
			<hr style={{ marginTop: '12px' }}/>
			<form onSubmit={handleSubmit}>
				<div className="upi-widget-title">Enter date of birth</div>
				<div className="upi-widget-subtitle">{subtitle ? subtitle : 'Enter the your date of birth to continue'}</div>
				<InputField
					type="date"
					id="birthday"
					name="birthday"
					value={birthday}
					autoFocus={true}
					inputRef={inputRef}
					label={'Date of Birth'}
					onChange={handleChange}
					inputStyle={!hasFocus ? { color: "transparent" } : {}}
					onFocus={event => {
						setHasFocus(true);
						handleFocus(event);
					}}
					onBlur={event => {
						setHasFocus(false);
						handleBlur(event);
					}}
					error={error}
				/>
				{error && <div className="upi-widget-text-error">
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				<div className="upi-widget-footer">
					<div className="left"><button type="button" onClick={Util.closeApp}>Cancel</button></div>
					<div className="vertical-line"/>
					<div className="right">
						{Util.device === 'ios' && (<IOSButton type="submit" className={buttonDisabled ? 'disabled' : ''} isLoading={loading}/>)}
						{Util.device !== 'ios' && (<button type="submit">Next</button>)}
					</div>
				</div>
			</form>
			{ loading && Util.device !== 'ios' && <Loading message={'Authorizing...'} /> }
		</div>
	);
};

export default Birthday;
